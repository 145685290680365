import './Card.css';

const Card = ({ info, showLeague }) => {

    const htShotsOn = info.shotsont45?.match(/\d+/g)
    const htShotsOff = info.shotofft45?.match(/\d+/g)
    const htDanAttacks = info.dattack45?.match(/\d+/g)
    const htCorners = info.corners45?.match(/\d+/g)

    const stats = [
        {
            label: 'Shots On',
            placeholder: 'Shots on target',
            ft: `${info.homesont} - ${info.awaysont}`,
            ht: htShotsOn?.length ? `${htShotsOn?.[0]} - ${htShotsOn?.[1]}` : undefined
        },
        {
            label: 'Shots Off',
            placeholder: 'Shots off target',
            ft: `${info.homesoft} - ${info.awaysoft}`,
            ht: htShotsOff?.length ? `${htShotsOff?.[0]} - ${htShotsOff?.[1]}` : undefined
        },
        // {
        //     label: 'Shots In',
        //     placeholder: 'Shots in box',
        //     ft: `${info.homesinbox} - ${info.awaysinbox}`,
        // },
        {
            label: 'Dangerous',
            placeholder: 'Dangerous Attacks',
            ft: `${info.homeda} - ${info.awayda}`,
            ht: htDanAttacks?.length ? `${htDanAttacks?.[0]} - ${htDanAttacks?.[1]}` : undefined
        },
        {
            label: 'Attacks',
            placeholder: 'Attacks',
            ft: `${info.attack?.match(/\d+/g)?.[0]} - ${info.attack?.match(/\d+/g)?.[1]}`
        },
        {
            label: 'Corners',
            placeholder: 'Corners',
            ft: `${info.homecorners} - ${info.awaycorners}`,
            ht: htCorners?.length ? `${htCorners?.[0]} - ${htCorners?.[1]}` : undefined
        },
        {
            label: 'Poss',
            placeholder: 'Possession',
            ft: `${info.homeposs} - ${info.awayposs}`,
        },
    ]

    const gameTime = () => {
        const times = info.time.split('<br />');
        return {
            time: times[0] === 'HT' ? 'HT' : Number(times[0].split('(')?.[0]) + Number(times[1]?.match(/\d+/g)[0] || 0),
            extra: times[0].split('(')?.[1]?.match(/\d+/g)[0],
        }
    }

    const hasSecondHalfData = gameTime().time !== 'HT' && gameTime().time >= 45 && !(gameTime().extra && gameTime().time < 90)

    const hasStatsData = info?.homesont && info?.homesoft && info?.homeda && info?.homecorners

    const shouldBeOnFire = (info.hpi1 > 52 && info.hpi2 > 7 && info.time !== 'HT') ? 1 : (info.api1 > 52 && info.api2 > 7 && info.time !== 'HT') ? 2 : 0

    const leagueName = info.league.split('> ').pop()

    return hasStatsData && (
        <div key={`${info.matchid}-info`} className={`card ${showLeague ? 'card__space-for-title' : ''}`}>
            {
                showLeague &&
                <h1 className="card__league">
                    {leagueName}
                </h1>
            }
            <div className='card__info'>
                <div className="card__info__time">
                    <span className="card__info__time--full">{gameTime().time}</span>
                    <span className="card__info__time--extra">{gameTime().extra && '+' + gameTime().extra}</span>
                </div>
                <div className={`card__info__home ${shouldBeOnFire === 1 ? 'card__info--fire' : ''}`}>
                    {info.homerc !== '0' && (<span className='card__info__reds'>{info.homerc}</span>)}
                    {info.homeyc !== '0' && (<span className='card__info__yellows'>{info.homeyc}</span>)}
                    <span>{info.homet}</span>
                </div>
                <div className="card__info__score">
                    <div className="card__info__score--FT">
                        {info.homescore}-{info.awayscore}
                    </div>
                    { info.hhtscr && (
                        <div className="card__info__score--HT">
                            {info.hhtscr}-{info.ahtscr}
                        </div>
                    )}
                </div>
                <div className={`card__info__away ${shouldBeOnFire === 2 ? 'card__info--fire' : ''}`}>
                    <span>{info.awayt}</span>
                    {info.awayyc !== '0' && (<span className='card__info__yellows'>{info.awayyc}</span>)}
                    {info.awayrc !== '0' && (<span className='card__info__reds'>{info.awayrc}</span>)}
                </div>
            </div>
            
            <div className="card__stats">
                {
                    stats.map((stat) => (
                        <div className="card__stats__item" title={stat.placeholder}>
                            <div className="card__stats__item--label">{stat.label}</div>
                            {hasSecondHalfData && (<div className="card__stats__item--HT">{stat.ht || '-'}</div>)}
                            <div className="card__stats__item--FT">{stat.ft || '-'}</div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Card;
