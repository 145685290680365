import React, { useEffect, useState } from 'react';
// import Card from '../components/Card/Card';
import Card from '../components/Card/Card';
import Loading from '../components/Loading/Loading';
import './Home.css';

const Home = () => {
    // consts
    // const ENDPOINT = 'https://soccerscanner.net/pro/jsonfiles/console.json'
    const ENDPOINT = 'https://matches-stats.onrender.com/soccer'
    // const ENDPOINT = ''
    // https://soccerscanner.net/pro/smflags/1157.png

    // states
    const [loading, setLoading] = useState(true)
    const [games, setGames] = useState([])
    const [audio] = useState(new Audio('https://soundbible.com/mp3/Fire_Burning-JaBa-810606813.mp3'));
    const [playing, setPlaying] = useState(false);
    let currentLeague = undefined

    // helpers
    const fetchData = () => {
        const options = {}

        fetch(ENDPOINT, options).then(res => res.json()).then(
            (res) => { 
                setGames(res)
                setLoading(false)
            },
            (err) => setLoading(true)
        );
    }

    const playSound = () => setPlaying(true);

    const showLeagueName = game => { // could have image like <img  width=20 height=12 src=smflags/372.png> Israel :- Ligat ha Al
        if (game.leagueid === currentLeague) return false
        currentLeague = game.leagueid
        return true
    }

    // use effects
    useEffect(() => {
        fetchData();
        const interval = setInterval(fetchData, 45000); // 45sec em 45sec atualiza
        return () => clearInterval(interval)
    }, [])
    
    useEffect(() => {
        playing ? audio.play() : audio.pause();
    }, [playing]);

    useEffect(() => {
        audio.volume = .45
        audio.addEventListener('ended', () => setPlaying(false));
        document.body.addEventListener('onload', playSound);

        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, []);
    
    useEffect(() => {
        const isAnyGameOnFire = games.some(game => ((game.hpi1 > 52 && game.hpi2 > 7) || (game.api1 > 52 && game.api2 > 7)) && game.time !== 'HT')
        if (isAnyGameOnFire && !playing) {
            playSound()
        }
    }, [games])

    // returns
    if (loading) return <Loading />

    return (
        <div className='cards'>
            {games.map((game) => <Card key={`${game.matchid}-card`} info={game} showLeague={showLeagueName(game)}/> )}
        </div>
    );
}

export default Home;
