import './App.css';
import Home from './scenes/Home';

const App = () => {
  return (
    <main className="container">
      <Home />
    </main>
  );
}

export default App;
